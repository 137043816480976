.App {
  text-align: center;
  padding-top:12vh;
  
}
.Emoji {
  font-size:50px;
  display:inline-flex;
  text-align: center;
  
  justify-content: center;

}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.ul, li {
  list-style-type: none; /* Remove bullets */
  display:inline-flex;
  padding:3px; 
  justify-content: center;
}
.x-icon, .telegram-icon {
max-width:35px;
border-radius:5px;
}

/* CSS */
.button-44 {
  margin-left:10px;
display:inline-flex !important; 
  background: #e62143;
  border-radius: 12px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-family: Mija,-apple-system,BlinkMacSystemFont,Roboto,"Roboto Slab","Droid Serif","Segoe UI",system-ui,Arial,sans-serif;
  font-weight: 700;
  font-size:medium;
  justify-content: center;
  padding: 7px 15px ;
  text-align: center;
  text-decoration: none;
  text-decoration-skip-ink: auto;
  text-shadow: rgba(0, 0, 0, .3) 1px 1px 1px;
  text-underline-offset: 1px;
  transition: all .2s ease-in-out;
  user-select: none;
  -webkit-user-select: none;
  border: 0;
}

.button-44:active,
.button-44:focus {
  border-bottom-style: none;
  border-color: #e90000;
  box-shadow: rgba(0, 0, 0, .3) 0 3px 3px inset;
  outline: 0;
}

.button-44:hover {
  border-bottom-style: none;
  border-color: #e90000;
  box-shadow: rgba(0, 0, 0, .3) 0 3px 3px inset;
  outline: 0;
}

.inputButton {

  
}
.emailInput {
  max-width:150px;
  margin-right:5px;
}
.handleInput{
  max-width:70px;  
  margin-right:5px;


}
.footer {
  position: fixed;
  left: 0;
  bottom:0;
  width: 100%;
  text-align: center;
  padding-bottom:20px;
}
.App-handle {
  color:blue;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.head {
font-size:30px;
}
.hero {
  font-size:50px;
}
.cta{
  font-size:35px;
}

